<template>
    <v-sheet 
        color="primary lighten-1"
        :height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
    <v-container fluid class="pt-8">
    <v-row 
        align-content="center"
        justify="center">
        <v-col  cols="12" sm="8" md="4">
            <v-card class="elevation-6">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>{{'Sign Up' | localize }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="white"
                            v-on="on"
                            @click="gotoHome">
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>{{'close' | localize }}</span>
                    </v-tooltip> -->
                </v-toolbar>
                <div class="text-left my-2 ml-2">
                    <span class="caption ml-2">
                        {{'Already have an account' | localize }}?
                    </span>
                    <v-btn small text 
                        class="text-capitalize" 
                        color="primary" 
                        to="/signin" 
                        :loading="loading">
                        {{'Sign In' | localize }}
                    </v-btn>
                </div>
                <v-divider></v-divider>
                <v-form v-model="valid" ref="form" validation>
                    <v-card-text>
                        <v-text-field
                            color="primary"
                            :label="'First name' | localize"
                            prepend-icon="mdi-account"
                            type="text"
                            v-model="firstName"
                        ></v-text-field>
                        <v-text-field color="primary" 
                            :label="'Last name' | localize" 
                            prepend-icon="mdi-account" 
                            type="text"
                            v-model="lastName"
                            :rules="[rules.required]">
                        </v-text-field>
                        <v-text-field
                            color="primary"
                            :label="'Email' | localize"
                            prepend-icon="mdi-email"
                            type="text"
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                        ></v-text-field>
                        <v-text-field
                            color="primary"
                            id="password"
                            :label="'Password' | localize"
                            prepend-icon="mdi-lock"
                            :hint="'min 6 characters' | localize"
                            minlength="6"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            v-model="password"
                            :rules="[rules.required, rules.min]"
                            @click:append="show = !show"
                        ></v-text-field>
                        <v-text-field 
                            prepend-icon="mdi-phone"
                            :label="'Phone' | localize"
                            color="primary" placeholder="+373 625-820-54"
                            v-model="phone"
                            :rules="[rules.required, rules.phone]">
                        </v-text-field>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small outlined
                            class="text-capitalize mb-4 mr-4"
                            color="primary"
                            @click="onSubmit"
                            :loading="loading"
                            :disabled="!valid || loading"
                            >
                            {{'confirm' | localize }}
                        </v-btn>
                        <v-btn small outlined 
                            class="text-capitalize mb-4 mr-4"
                            color="primary"
                            to="/" 
                            :loading="loading" 
                            :disabled="loading">
                            {{'close' | localize }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</v-sheet>
</template>
<script>

import localizeFilter from '../filters/localize.filter'

export default {
    data() {
        return {
            dialog: false,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phone: '',
            message: '',
            valid: false,
            show: false,
            rules: {
                required: value => !!value || localizeFilter('required field'),
                min: v => v.length >= 6 || localizeFilter('min 6 characters'),
                phone: value => {
                const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
                return pattern.test(value) || localizeFilter('invalid phone')
                },
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || localizeFilter('invalid e-mail')
                }
            }
        }
    },
    computed: {
       loading() {
            return this.$store.getters.loading;
       },
        user() {
            return this.$store.getters.user;
        },
    },
    methods: {
        async onSubmit() {
           const user = {}

            if (this.$refs.form.validate()) {
                user.firstName = this.firstName
                user.lastName = this.lastName
                user.email = this.email
                user.password = this.password 
                user.photoURL = ''
                user.phone = this.phone   
            }
            try {
                //sign up
                await this.$store.dispatch('registration', user);
                
                //reset 
                this.dialog = false
                this.firstName = ''
                this.lastName = ''
                this.email = ''
                this.password = ''
                this.phone = ''
                this.message = ''
                this.valid = false
                this.show = false
                
                this.$router.replace('/signin')

            }
            catch (error) { 
                await this.$store.dispatch('setError', error.code);
            }
        }, 
        gotoHome() {
            this.$router.push({path: '/', hash: '#products'});
        }
    },
}
</script>